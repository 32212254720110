import { useLocalStorage } from '@uidotdev/usehooks';

import { UserLocation } from '@/api/gateway-click-collect/locations';

export const useUserLocation = () => {
  const [userLocation, setUserLocation] = useLocalStorage<UserLocation | null>('userLocation', null);

  const resetUserlocation = () => {
    setUserLocation(null);
  };

  return {
    resetUserlocation,
    setUserLocation,
    userLocation,
  };
};
