import { HStack, Skeleton, Stack, Text } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import { Navigate } from 'react-router-dom';

import { useTerminalQuery } from '@/api/gateway-click-collect/terminals/useTerminalQuery';
import { AsyncContent } from '@/components/AsyncContent';
import { PairTerminalButton } from '@/components/PairingTerminal/PairTerminalButton';
import { useTerminal, useUserLocation } from '@/hooks';
import { PairDeviceLayout } from '@/routes/PayOnSite/PairDeviceLayout';
import { DeviceInfo } from '@/routes/TerminalSettingsPage/DeviceInfo';
import { TerminalStatusCard } from '@/routes/TerminalSettingsPage/TerminalStatusCard';
import { UserInfo } from '@/routes/TerminalSettingsPage/UserInfos';

export const TerminalSettingsPage = () => {
  const { userLocation } = useUserLocation();
  const { terminal } = useTerminal();

  const {
    data: terminalStatus,
    isError,
    isLoading,
    refetch,
    dataUpdatedAt,
    isRefetching,
  } = useTerminalQuery({
    options: {
      enabled: !!terminal,
    },
    requestParams: terminal
      ? {
          locationUuid: terminal.terminalInfo.locationUuid,
          terminalUuid: terminal.terminalInfo.terminalUuid,
        }
      : undefined,
  });

  if (!userLocation || !terminal) {
    return <Navigate to="/onSite/internalLocations" />;
  }

  const { kitchenLabel, locationUuid } = userLocation;

  return (
    <PairDeviceLayout
      hasLogOutButton
      headerTitle={t`Settings`}
      hasBackButton
      url={`/onSite/${locationUuid}`}
      backButtonText={t`Back to menu`}
    >
      <Stack gap={4} maxW={700} justifyContent="center" mx="auto" px={2}>
        <UserInfo kitchenLabel={kitchenLabel} />
        <HStack justifyContent="space-between">
          <Text fontSize={20}>
            <Trans>Device Information</Trans>
          </Text>
          <PairTerminalButton />
        </HStack>
        <AsyncContent
          data={terminalStatus}
          isLoading={isLoading}
          isError={isError}
          hasData={!!terminalStatus}
          LoadingContent={
            <Stack gap={2}>
              <Skeleton height={50} />
              <Skeleton height={50} />
              <Skeleton height={50} />
            </Stack>
          }
          renderContent={({ status, locationUuid, terminalUuid }) => (
            <Stack gap={6}>
              <TerminalStatusCard
                lastUpdateAt={dataUpdatedAt}
                isRefreshing={isRefetching}
                locationUuid={locationUuid}
                onRefreshClick={() => {
                  refetch();
                }}
                terminalStatus={status}
                terminalUuid={terminalUuid}
              />
              <DeviceInfo cardMachineId={terminalUuid} deviceId={terminal.authorization.keyId} />
            </Stack>
          )}
        />
      </Stack>
    </PairDeviceLayout>
  );
};
