import { CloseIcon } from '@chakra-ui/icons';
import { Button, Grid, GridItem, Icon, Spinner, Stack, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { AiOutlineWarning } from 'react-icons/ai';

import { RegisteredTerminalStatusEnum } from '@/api/gateway-click-collect/terminals';
import { useCancelActionMutation } from '@/api/gateway-click-collect/terminals';
import Card from '@/components/Card';
import { PairTerminalButton } from '@/components/PairingTerminal/PairTerminalButton';

export interface TerminalIssuesCardProps {
  locationUuid: string;
  onPaymentCancelation: () => void;
  terminalStatus: RegisteredTerminalStatusEnum;
  terminalUuid: string;
}

export const TerminalIssuesCard = ({
  locationUuid,
  onPaymentCancelation,
  terminalStatus,
  terminalUuid,
}: TerminalIssuesCardProps) => {
  const { mutate: deleteOngoingPayment, isLoading } = useCancelActionMutation();

  const isBusy = terminalStatus === RegisteredTerminalStatusEnum.Busy;
  const isOffline = terminalStatus === RegisteredTerminalStatusEnum.Offline;
  const isUnavailable = terminalStatus === RegisteredTerminalStatusEnum.Unavailable;

  if (isOffline || isUnavailable) {
    return (
      <Card sx={{ background: 'red.warning.25', borderColor: 'red.warning.300', color: 'red.warning.700' }}>
        <Grid templateColumns="30px 1fr">
          <GridItem>
            <Icon as={AiOutlineWarning} width={5} height={5} color="red.warning.600" />
          </GridItem>
          <Stack>
            <Text>{isUnavailable ? <Trans>Card machine unavailable</Trans> : <Trans>Card machine offline</Trans>}</Text>
            <Text fontWeight={300}>
              {isUnavailable ? (
                <Trans>To process payments, pair the card machine again.</Trans>
              ) : (
                <Trans>To continue, turn the card machine on.</Trans>
              )}
            </Text>
            {isUnavailable && <PairTerminalButton sx={{ width: 'fit-content' }} />}
          </Stack>
        </Grid>
      </Card>
    );
  }

  if (isBusy) {
    return (
      <Card sx={{ background: 'transparent', boxShadow: 'unset', borderColor: 'primary.300' }}>
        <Grid templateColumns="30px 1fr">
          <GridItem>
            <Spinner size="xs" />
          </GridItem>
          <Stack gap={1}>
            <Text>
              <Trans>Payment request in progress</Trans>
            </Text>
            <Text fontWeight={300}>
              <Trans>To make the card machine available again, cancel the payment request.</Trans>
            </Text>
            <Button
              onClick={() =>
                deleteOngoingPayment(
                  { locationUuid, terminalUuid },
                  {
                    onSuccess: () => {
                      onPaymentCancelation();
                    },
                  }
                )
              }
              leftIcon={<CloseIcon />}
              isLoading={isLoading}
              loadingText={<Trans>Canceling payment</Trans>}
              colorScheme="red"
              alignSelf="flex-start"
            >
              <Trans>Cancel payment request</Trans>
            </Button>
          </Stack>
        </Grid>
      </Card>
    );
  }

  return null;
};
