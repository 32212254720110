import { Heading, Image, Stack, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useWindowSize } from '@uidotdev/usehooks';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ConceptInformation } from '@/api/gateway-click-collect/configurations';
import { AbandonCartModal } from '@/components/Kiosk/KioskAbandonCartButton/AbandonCartModal';
import { KioskBackButton } from '@/components/Kiosk/KioskBackButton';
import { useCart } from '@/contexts/cart';
import { SessionEndReasonEnum, useOnSiteSession } from '@/contexts/onSiteSession';
import { getResponsivePictureClassification } from '@/helpers/onSite/formatOnsiteMenuPictures';

export interface HeaderProps {
  conceptInformation: ConceptInformation;
  locationUuid: string;
}

export const MenuCategoriesPageHeader = ({ conceptInformation, locationUuid }: HeaderProps) => {
  const { width } = useWindowSize();
  const { i18n } = useLingui();
  const { conceptName, tagLines, heroPictures } = conceptInformation;

  const tagLine = tagLines[i18n.locale] ?? Object.values(tagLines)[0];
  const heroPicture = getResponsivePictureClassification({
    pictureClassifications: heroPictures,
    windowWidth: width ?? 0,
  }).url;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  const { endOnSiteSession } = useOnSiteSession();

  const { cart } = useCart();

  const handleAbandonClick = () => {
    endOnSiteSession({
      sessionEndReason: SessionEndReasonEnum.ABANDON,
    });

    navigate(`/onSite/${locationUuid}`);
  };

  return (
    <Stack
      bg="white"
      boxShadow="0px 4px 16px 0px #0000001A"
      direction="row"
      height={{ sm: '180px', lg: '210px' }}
      margin={0}
      spacing={0}
      minH={82}
      width="100%"
      position="relative"
    >
      <KioskBackButton
        onClick={() => {
          cart.items.length ? setIsModalOpen(true) : navigate(`/onSite/${locationUuid}`);
        }}
        sx={{
          backgroundColor: 'white',
          left: 2,
          position: 'absolute',
          top: 2,
        }}
      />
      <Image
        src={heroPicture}
        display={{ base: 'none', sm: 'block' }}
        width={{ base: '48vw', lg: '35vw' }}
        objectFit="cover"
      />
      <Stack gap={3} p={4} marginTop="0 !important" justifyContent="center" width="100%">
        <Heading
          fontSize={{ lg: '40px', base: '16px', md: '32px', '2xl': '48px' }}
          marginLeft={{ base: '64px', sm: 0 }}
          textAlign={{ base: 'end', sm: 'start' }}
          textTransform="uppercase"
        >
          <Trans>Welcome to {conceptName}</Trans>
        </Heading>
        {tagLine && (
          <Text
            fontSize={{
              base: 24,
              '2xl': 32,
            }}
            display={{ base: 'none', sm: 'block' }}
          >
            {tagLine}
          </Text>
        )}
      </Stack>
      {!!cart.items.length && (
        <AbandonCartModal isOpen={isModalOpen} onCancel={() => setIsModalOpen(false)} onConfirm={handleAbandonClick} />
      )}
    </Stack>
  );
};
