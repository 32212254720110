import { Button, Checkbox, FormControl, FormErrorMessage, FormLabel, Input, Stack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Trans } from '@lingui/macro';
import { Controller, useForm, UseFormReset } from 'react-hook-form';
import * as yup from 'yup';

type ResetReceiptForm = UseFormReset<{
  email: string;
  isOptedIn: boolean;
}>;

export type SubmitReceiptEventHandler = (email: string, isOptedIn: boolean, options: ResetReceiptForm) => void;

export interface ReceiptFormProps {
  isLoading: boolean;
  onSubmit: SubmitReceiptEventHandler;
}

export const ReceiptForm = ({ isLoading, onSubmit }: ReceiptFormProps) => {
  const { control, handleSubmit, reset } = useForm({
    defaultValues: { email: '', isOptedIn: false },
    resolver: yupResolver(
      yup.object({
        email: yup.string().required().email(),
        isOptedIn: yup.boolean(),
      })
    ),
  });

  const sendReceipt = handleSubmit((formData) => {
    const { email, isOptedIn } = formData;

    onSubmit(email, isOptedIn, reset);
  });

  return (
    <Stack as="form" gap={2} onSubmit={sendReceipt}>
      <Controller
        name="email"
        control={control}
        render={({ field, fieldState: { invalid } }) => (
          <FormControl isInvalid={invalid}>
            <FormLabel>
              <Trans>Email</Trans>
            </FormLabel>
            <Input {...field} isInvalid={invalid} />
            <FormErrorMessage>
              <Trans>Please enter a valid email address</Trans>
            </FormErrorMessage>
          </FormControl>
        )}
      />
      <Controller
        control={control}
        name="isOptedIn"
        render={({ field: { value, ...fieldRest } }) => (
          <Checkbox {...fieldRest} isChecked={value} spacing={6} mt={1}>
            <Trans>Yes, I would like to be the first to know about exclusive offers and discounts.</Trans>
          </Checkbox>
        )}
      />
      <Button size="lg" type="submit" isLoading={isLoading} marginTop={6}>
        <Trans>Send receipt</Trans>
      </Button>
    </Stack>
  );
};
