import { useEffect, useMemo } from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';

import { ConceptInformation, useConceptsConfigurationQuery } from '@/api/gateway-click-collect';
import {
  Menu,
  Restaurant,
  useRestaurantMenuQuery,
  useRestaurantOutOfStocksQuery,
  useRestaurantQuery,
} from '@/api/gateway-click-collect/restaurants';
import { InitializationBundle } from '@/api/gateway-click-collect/terminals';
import { InactiveUserModal } from '@/components/InactiveUserModal/InactiveUserModal';
import PageLoader from '@/components/PageLoader';
import { HOUR_MS, MINUTE_MS } from '@/constants';
import { useCart } from '@/contexts/cart';
import { useTerminalContext } from '@/contexts/TerminalProvider';
import NotFoundPage from '@/routes/NotFoundPage';

export interface MenuDataContext {
  conceptInformation: ConceptInformation;
  menu: Menu;
  outOfStocks: Set<string>;
  restaurant: Restaurant;
  terminal: InitializationBundle;
}

export const useMenuDataContext = () => useOutletContext<MenuDataContext>();

export const MenuDataProvider = () => {
  const { restaurantPlatformId } = useParams() as { restaurantPlatformId: string };
  const { userLocation, terminal } = useTerminalContext();
  const { locationUuid } = userLocation;

  const { cart, resetCart } = useCart();

  useEffect(() => {
    if (cart.restaurantPlatformId !== restaurantPlatformId) {
      resetCart();
    }
  }, [cart, resetCart, restaurantPlatformId]);

  const { data: conceptConfigs, isLoading: isLoadingConceptConfigs } = useConceptsConfigurationQuery({
    options: {
      cacheTime: Infinity,
      refetchInterval: HOUR_MS * 3,
    },
  });

  const { data: restaurant, isLoading: isLoadingRestaurant } = useRestaurantQuery({
    options: {
      cacheTime: Infinity,
      refetchInterval: MINUTE_MS * 5,
    },
    requestParams: {
      restaurantPlatformId,
      withOpeningInformation: true,
    },
  });

  const { data: menu, isLoading: isLoadingOutOfStocks } = useRestaurantMenuQuery({
    options: { cacheTime: Infinity, refetchInterval: MINUTE_MS * 5 },
    requestParams: { restaurantPlatformId },
  });

  const { data: outOfStocks, isLoading: isLoadingMenu } = useRestaurantOutOfStocksQuery({
    options: { cacheTime: Infinity, refetchInterval: MINUTE_MS * 5, select: (response) => new Set(response) },
    requestParams: { restaurantPlatformId },
  });

  const conceptInformation = useMemo(() => {
    if (conceptConfigs && restaurant) {
      return conceptConfigs.concepts.find(({ uuid }) => uuid === restaurant.conceptUuid);
    }
  }, [conceptConfigs, restaurant]);

  const isLoading = isLoadingConceptConfigs || isLoadingRestaurant || isLoadingMenu || isLoadingOutOfStocks;

  if (conceptInformation && menu && outOfStocks && restaurant)
    return (
      <>
        <Outlet
          context={
            {
              conceptInformation,
              menu,
              outOfStocks,
              restaurant,
              terminal,
            } satisfies MenuDataContext
          }
        />
        <InactiveUserModal locationUuid={locationUuid} />
      </>
    );

  if (isLoading) {
    return <PageLoader />;
  }

  return <NotFoundPage to={`/onSite/${locationUuid}`} />;
};
