import { cloneDeep } from 'lodash-es';
import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { CartItemModifierGroup } from '@/api/gateway-click-collect/orders';
import { AddModifierGroup } from '@/components/AddModifierGroup';
import { KioskOutOfStockContent } from '@/components/ItemOutOfStockContent';
import { useMenuDataContext } from '@/contexts';
import { useCart } from '@/contexts/cart';
import dataLayer from '@/helpers/dataLayer.helpers';
import { getMenuPicturesMap, updateCart } from '@/helpers/menu.helpers';
import { usePollingTerminalStatus } from '@/hooks/usePollingTerminalStatus';

import { getCurrentAvailableItem, getDeprecatedItemCart } from './AddItemPage.helpers';

export const AddItemPage = () => {
  const { restaurantPlatformId, categoryUuid, menuElementUuid } = useParams() as {
    categoryUuid: string;
    menuElementUuid: string;
    restaurantPlatformId: string;
  };
  const navigate = useNavigate();

  const { cart, setCart } = useCart();
  const {
    menu,
    outOfStocks,
    conceptInformation,
    restaurant: {
      address: restaurantAddress,
      currencyCode,
      kitchenInstructions,
      kitchenLabel,
      restaurantLabel,
      locationUuid,
    },
    terminal: { terminalInfo },
  } = useMenuDataContext();

  const [activeModifierGroupIndex, setActiveModifierGroupIndex] = useState(0);

  const draftCartItemModifierGroups = useRef<CartItemModifierGroup[]>([]);
  const { current: currentItem } = useRef(cloneDeep(getCurrentAvailableItem({ menu, menuElementUuid, outOfStocks })));

  usePollingTerminalStatus({
    locationUuid,
    terminalUuid: terminalInfo.terminalUuid,
    maxTry: 5,
  });

  if (!currentItem) {
    return <KioskOutOfStockContent redirectUrl={`/onSite/menu/${restaurantPlatformId}`} />;
  }

  const pictures = getMenuPicturesMap(menu);
  const modifierGroups = currentItem.modifierGroups ?? [];
  const isOnLastModifierGroup = activeModifierGroupIndex === modifierGroups.length - 1;
  const activeModifierGroup = modifierGroups[activeModifierGroupIndex];

  const handleItemSelect = (newCartItemModifierGroup?: CartItemModifierGroup) => {
    if (newCartItemModifierGroup) {
      draftCartItemModifierGroups.current = [...draftCartItemModifierGroups.current, newCartItemModifierGroup];
    }

    if (isOnLastModifierGroup) {
      const itemCart = getDeprecatedItemCart({
        cartItemModifierGroups: draftCartItemModifierGroups.current,
        item: currentItem,
        picture: pictures[currentItem.menuItemUuid],
      });

      const cartItems = updateCart({
        item: itemCart,
        cart,
      });

      setCart({
        items: cartItems,
        kitchenLabel,
        conceptLabel: conceptInformation.conceptName,
        accessInstructions: kitchenInstructions,
        restaurantAddress: restaurantAddress,
        restaurantName: restaurantLabel,
        restaurantPlatformId,
      });

      dataLayer.logItemAdded(kitchenLabel, conceptInformation.conceptName, itemCart, 'onsite');

      navigate(`/onSite/menu/${restaurantPlatformId}/categories/${categoryUuid}`);
    } else {
      setActiveModifierGroupIndex(activeModifierGroupIndex + 1);
    }
  };

  const handleBackButtonClick = () => {
    if (activeModifierGroupIndex) {
      setActiveModifierGroupIndex(activeModifierGroupIndex - 1);

      const activeModifierGroupUuid = modifierGroups[activeModifierGroupIndex - 1].modifierGroupUuid;

      draftCartItemModifierGroups.current = draftCartItemModifierGroups.current.filter(
        ({ modifierGroupUuid }) => modifierGroupUuid !== activeModifierGroupUuid
      );
    } else navigate(`/onSite/menu/${restaurantPlatformId}/categories/${categoryUuid}`);
  };

  return (
    <AddModifierGroup
      key={activeModifierGroup.modifierGroupUuid}
      onSelect={handleItemSelect}
      onSkipModifier={handleItemSelect}
      onBackButtonClick={handleBackButtonClick}
      restaurantPlatformId={restaurantPlatformId}
      modifierGroup={activeModifierGroup}
      pictures={pictures}
      currencyCode={currencyCode}
    />
  );
};
