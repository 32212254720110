import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import ApiError from '@/api/ApiError';
import { useTasterApis } from '@/api/TasterApiContext';

import { ordersQueryKeys } from './orders.helpers';
import { GetOrderRequest, ReadiedOrderSummary } from './types';

export const useOrderQueryOptions = {
  queryKey: (requestParams?: GetOrderRequest) => [...ordersQueryKeys.all, 'get', requestParams] as const,
};

export interface UseOrderQueryProps<TData = ReadiedOrderSummary> {
  options?: Omit<
    UseQueryOptions<ReadiedOrderSummary, ApiError, TData, ReturnType<typeof useOrderQueryOptions.queryKey>>,
    'queryFn' | 'queryKey'
  >;
  requestParams?: GetOrderRequest;
}

export const useOrderQuery = <TData = ReadiedOrderSummary>({ options, requestParams }: UseOrderQueryProps<TData>) => {
  const {
    gatewayClickCollect: { ordersApi },
  } = useTasterApis();

  return useQuery({
    ...options,
    queryKey: useOrderQueryOptions.queryKey(requestParams),
    queryFn: () => {
      if (!requestParams) {
        return Promise.reject('Invalid query params');
      }

      return ordersApi.getOrder(requestParams);
    },
  });
};
