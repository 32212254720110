import { CloseIcon } from '@chakra-ui/icons';
import { Button, IconButton, Image, Modal, ModalContent, ModalOverlay, Stack, Text } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';

import Warning from '@/assets/img/warning.svg';
import { useCart } from '@/contexts/cart';

export interface AbandonCartModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
}

export const AbandonCartModal = ({ isOpen, onConfirm, onCancel }: AbandonCartModalProps) => {
  const { resetCart } = useCart();

  return (
    <Modal isOpen={isOpen} onClose={onCancel} size="xl">
      <ModalOverlay />
      <ModalContent
        px={20}
        py={8}
        minWidth="700px"
        width="fit-content"
        maxW="100%"
        maxHeight="100%"
        my="auto"
        position="relative"
      >
        <IconButton
          alignSelf="end"
          aria-label={t`Close`}
          border="1px solid"
          borderColor="gray.border"
          boxShadow="0px 1px 2px 0px #1018280D"
          colorScheme="gray"
          fontSize={{ base: 16, kiosk: 28 }}
          height="104px"
          icon={<CloseIcon />}
          onClick={onCancel}
          position="absolute"
          right={8}
          top={8}
          variant="outline"
          width="104px"
        />
        <Stack alignItems="center" height="100%" pb={12}>
          <Stack gap={8} alignItems="center" justifyContent="space-between" maxWidth={470} height="100%">
            <Stack gap={4} alignItems="center">
              <Image src={Warning} width={200} height={200} />
              <Text fontSize={42} fontWeight={700} textAlign="center">
                <Trans>Clear cart?</Trans>
              </Text>
            </Stack>
            <Stack gap={6}>
              <Button
                colorScheme="red"
                size="lg"
                textTransform="uppercase"
                height={{ base: '40px', kiosk: '80px' }}
                fontSize={{ base: 16, kiosk: 28 }}
                onClick={() => {
                  resetCart();
                  onConfirm();
                }}
              >
                <Trans>Clear cart</Trans>
              </Button>
              <Button
                size="lg"
                textTransform="uppercase"
                height={{ base: '40px', kiosk: '80px' }}
                fontSize={{ base: 16, kiosk: 28 }}
                onClick={onCancel}
              >
                <Trans>Continue ordering</Trans>
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </ModalContent>
    </Modal>
  );
};
