import { ArrowBackIcon } from '@chakra-ui/icons';
import { Button, Heading, Image, Stack, Text } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

import {
  ReadiedOrderSummary,
  useOrderQueryOptions,
  usePrepareTerminalOrderMutation,
} from '@/api/gateway-click-collect/orders';
import { useCancelActionMutation } from '@/api/gateway-click-collect/terminals';
import cycleAnimation from '@/assets/anim/lottie_cycle.json';
import warningImg from '@/assets/img/warning.svg';
import { CustomerNameForm } from '@/components/CustomerNameForm';
import { LottieAnimation } from '@/components/LottieAnimation';
import { useTerminalContext } from '@/contexts';
import { useCart } from '@/contexts/cart';
import { formatCartForValidation } from '@/helpers/cart.helpers';

import { PairDeviceLayout } from './PairDeviceLayout';

// TODO check for empty cart (possibly remove restaurantPlatformId query params if cart exists)
export const CustomerNamePage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { restaurantPlatformId } = useParams() as { restaurantPlatformId: string };
  const { terminal } = useTerminalContext();
  const { locationUuid, terminalUuid } = terminal.terminalInfo;
  const { cart } = useCart();

  const { mutate: cancelTerminalAction } = useCancelActionMutation();

  const {
    isError: hasOrderError,
    isLoading: isPreparingOrder,
    mutate: prepareTerminalOrder,
  } = usePrepareTerminalOrderMutation({
    onSuccess: (order) => {
      queryClient.setQueryData<ReadiedOrderSummary>(
        useOrderQueryOptions.queryKey({ orderUuid: order.uuid }),
        () => order
      );
    },
  });

  const handleSubmit = (customerName: string) => {
    prepareTerminalOrder(
      {
        cart: { ...formatCartForValidation(cart, terminalUuid), customerName, couponCode: undefined },
      },
      {
        onError: (error) => {
          if (error.response.status >= 500) {
            cancelTerminalAction({ locationUuid, terminalUuid });
          }
        },
        onSuccess: (order) => {
          navigate(`/onSite/${restaurantPlatformId}/paymentInstructions/${order.uuid}`);
        },
      }
    );
  };

  if (isPreparingOrder) {
    return (
      <PairDeviceLayout headerTitle={t`Checkout`}>
        <Stack alignItems="center">
          <LottieAnimation animation={cycleAnimation} sx={{ width: '250px' }} />
          <Heading as="span" fontWeight={700} fontSize={32}>
            <Trans>Processing order…</Trans>
          </Heading>
        </Stack>
      </PairDeviceLayout>
    );
  }

  if (hasOrderError) {
    return (
      <PairDeviceLayout headerTitle={t`Checkout`}>
        <Stack alignItems="center" px={4}>
          <Image src={warningImg} width={20} height={20} />
          <Text>
            <Trans>There was an error processing your order.</Trans>
          </Text>
          <Text>
            <Trans>Please try again.</Trans>
          </Text>
          <Button
            colorScheme="gray"
            marginTop={4}
            onClick={() => {
              navigate(`/onSite/menu/${restaurantPlatformId}/cart`);
            }}
          >
            <Trans>Go back to my cart</Trans>
          </Button>
        </Stack>
      </PairDeviceLayout>
    );
  }

  return (
    <PairDeviceLayout headerTitle={t`Checkout`}>
      <Stack px={4} alignItems="center">
        <Button
          colorScheme="gray"
          leftIcon={<ArrowBackIcon />}
          sx={{ alignSelf: 'flex-start' }}
          variant="ghost"
          onClick={() => {
            navigate(`/onSite/menu/${restaurantPlatformId}/cart`);
          }}
        >
          <Trans>Back</Trans>
        </Button>
        <CustomerNameForm onSubmit={handleSubmit} isSubmitting={isPreparingOrder} />
      </Stack>
    </PairDeviceLayout>
  );
};
