import { Icon } from '@chakra-ui/icons';
import {
  Button,
  ButtonProps,
  chakra,
  FormControl,
  FormHelperText,
  Heading,
  IconButton,
  Image,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiSettings } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import AdminSettingsAccess from '@/assets/img/admin_settings_acces.svg';

const StyledInput = chakra(Input, {
  baseStyle: {
    padding: '16px',
    border: '1px solid gray.300',
    textAlign: 'center',
    height: '60px',
    maxW: 'unset',
    fontSize: 24,
    letterSpacing: 20,
  },
});

interface SettingsAccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  onFormSubmit: () => void;
}

type Input = {
  pinInputValue: string;
};

const SettingsAccessModal = ({ isOpen, onClose, onFormSubmit }: SettingsAccessModalProps) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm<Input>({ mode: 'all' });

  const onSubmit = () => {
    onFormSubmit();
  };

  const isPinInputInvalid = getValues().pinInputValue?.length >= 5 && !!errors.pinInputValue;

  if (isPinInputInvalid) {
    setIsFormSubmitted(true);
    reset();
    getValues();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl" allowPinchZoom>
      <ModalOverlay />
      <ModalContent width="95vw" maxWidth={740} top={0}>
        <ModalCloseButton size="lg" rounded="full" />
        <Stack gap={8} alignItems="center" px={3} py={12}>
          <Image src={AdminSettingsAccess} />
          <Heading fontSize={32} textAlign="center">
            <Trans>Admin settings</Trans>
          </Heading>
          <Text color="gray.700" fontSize={24} textAlign="center">
            <Trans>To access settings, please enter the admin PIN.</Trans>
          </Text>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={2} alignItems="center">
              <Text color="gray.700" fontSize={24} textAlign="center">
                <Trans>Enter the admin PIN</Trans>
              </Text>
              <FormControl isInvalid={isFormSubmitted}>
                <StyledInput
                  pattern="[0-9]*"
                  inputMode="numeric"
                  type="password"
                  {...register('pinInputValue', {
                    required: true,
                    validate: (value) => value === '07139',
                  })}
                />
                {isFormSubmitted && (
                  <FormHelperText color="red.700">
                    <Trans id={'Incorrect code'}>Wrong code</Trans>
                  </FormHelperText>
                )}
              </FormControl>
              <Button sx={{ fontSize: 24, height: '60px', width: '100%' }} type="submit">
                <Trans>Submit</Trans>
              </Button>
            </Stack>
          </form>
        </Stack>
      </ModalContent>
    </Modal>
  );
};

export const SettingsAccessButton = ({ sx, ...prop }: ButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      <SettingsAccessModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onFormSubmit={() => navigate('/onSite/terminalSettings')}
      />
      <IconButton
        onClick={() => setIsModalOpen(true)}
        variant="ghost"
        aria-label={t`Settings`}
        icon={<Icon as={FiSettings} color="gray.icons" />}
        sx={{
          height: '33px',
          width: '33px',
          ...sx,
        }}
        {...prop}
      />
    </>
  );
};
