import { CheckCircleIcon } from '@chakra-ui/icons';
import { Button, HStack, Icon, Spinner, Stack, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import dayjs from 'dayjs';
import { ReactNode, useEffect, useState } from 'react';
import { AiOutlineWarning } from 'react-icons/ai';
import { MdOutlineCloudOff } from 'react-icons/md';

import { RegisteredTerminalStatusEnum } from '@/api/gateway-click-collect/terminals';
import Card from '@/components/Card';
import { MINUTE_MS } from '@/constants';
import { RefreshIcon } from '@/routes/TerminalSettingsPage/RefreshIcon';
import { TerminalIssuesCard } from '@/routes/TerminalSettingsPage/TerminalIssuesCard';

export interface TerminalStatusCardProps {
  lastUpdateAt: number;
  isRefreshing?: boolean;
  locationUuid: string;
  onRefreshClick: (locationUuid: string, terminalUuid: string) => void;
  terminalStatus: RegisteredTerminalStatusEnum;
  terminalUuid: string;
}

const TerminalSatusRenderingContent: Record<string, ReactNode> = {
  [RegisteredTerminalStatusEnum.Ready]: (
    <>
      <CheckCircleIcon color="green.success.icon" width={5} height={5} />
      <Text color="green.600" fontSize={20}>
        <Trans>Ready</Trans>
      </Text>
    </>
  ),
  [RegisteredTerminalStatusEnum.Offline]: (
    <>
      <Icon as={MdOutlineCloudOff} width={5} height={5} />
      <Text color="primary.700" fontSize={20}>
        <Trans>Offline</Trans>
      </Text>
    </>
  ),
  [RegisteredTerminalStatusEnum.Busy]: (
    <>
      <Spinner size="xs" />
      <Text fontSize={20}>
        <Trans>Payment request in progress</Trans>
      </Text>
    </>
  ),
  [RegisteredTerminalStatusEnum.Unavailable]: (
    <>
      <Icon as={AiOutlineWarning} color="red.warning.600" width={5} height={5} />
      <Text color="red.warning.600" fontSize={20}>
        <Trans>Unavailable</Trans>
      </Text>
    </>
  ),
};

export const TerminalStatusCard = ({
  lastUpdateAt,
  isRefreshing = false,
  locationUuid,
  onRefreshClick,
  terminalStatus,
  terminalUuid,
}: TerminalStatusCardProps) => {
  const [lastUpdateRelativeTime, setLastUpdateRelativeTime] = useState(() => dayjs().to(lastUpdateAt));

  useEffect(() => {
    setLastUpdateRelativeTime(dayjs().to(lastUpdateAt));

    const interval = setInterval(() => {
      setLastUpdateRelativeTime(dayjs().to(lastUpdateAt));
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, [lastUpdateAt]);

  const handleRefreshClick = () => {
    onRefreshClick(locationUuid, terminalUuid);
  };

  return (
    <Stack>
      <TerminalIssuesCard
        locationUuid={locationUuid}
        onPaymentCancelation={handleRefreshClick}
        terminalStatus={terminalStatus}
        terminalUuid={terminalUuid}
      />
      <Card>
        <Stack gap={1}>
          <HStack justifyContent="space-between">
            <Text fontSize={20}>
              <Trans>Status</Trans>
            </Text>
            <HStack gap={1}>{TerminalSatusRenderingContent[terminalStatus]}</HStack>
          </HStack>
          <HStack>
            <Button
              alignSelf="end"
              leftIcon={<RefreshIcon isRefreshing={isRefreshing} />}
              ml="auto"
              onClick={handleRefreshClick}
              variant="text"
              width="fit-content"
              colorScheme="gray"
            >
              <Trans>Refresh</Trans>
            </Button>
            <Text fontWeight={300}>
              <Trans>Updated {lastUpdateRelativeTime}</Trans>
            </Text>
          </HStack>
        </Stack>
      </Card>
    </Stack>
  );
};
