/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { useRestaurantMenuQuery, useRestaurantQuery } from '@/api/gateway-click-collect';
import type { Order } from '@/api/types';
import { useOrderQuery } from '@/api/useOrderQuery';
import { useSendCustomerEmailMutation } from '@/api/useSendCustomerEmailMutation';
import IntercomLauncher from '@/components/IntercomLauncher';
import OrderDetailsCard from '@/components/Order/OrderDetailsCard';
import OrderInfosCard from '@/components/Order/OrderInfosCard';
import OrderPickupMap from '@/components/Order/OrderPickupMap';
import OrderStatusCard from '@/components/Order/OrderStatusCard';
import { SubmitReceiptEventHandler } from '@/components/Order/Receipt/ReceiptForm';
import { ReceiptModal } from '@/components/Order/Receipt/ReceiptModal';
import { ReceiptStatus } from '@/components/Order/Receipt/ReceiptStatus';
import PageLoader from '@/components/PageLoader';
import { useOrder } from '@/contexts/order';
import { getMenuPicturesMap } from '@/helpers/menu.helpers';
import usePageViewTracker from '@/hooks/usePageViewTracker';
import { AppLayout } from '@/layouts/AppLayout';
import { OrderStatus } from '@/types';

export const OrderStatusPage = () => {
  const { orderUuid } = useParams() as { orderUuid: string };

  const { order, orderStatus, setOrder } = useOrder();

  const {
    data: fetchedOrder,
    error,
    isLoading,
  } = useOrderQuery({
    orderUuid,
    options: {
      refetchInterval: 3000,
    },
  });

  usePageViewTracker();

  if (fetchedOrder && fetchedOrder !== order) {
    setOrder(fetchedOrder);
  }

  if (error || !orderUuid) {
    return <Navigate to={`/notFound`} />;
  }

  if (isLoading || !order) {
    return <PageLoader />;
  }

  return <OrderStatusContainer order={order} orderStatus={orderStatus} />;
};

const OrderStatusContainer = ({ order, orderStatus }: { order: Order; orderStatus?: OrderStatus }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [paymentUuid] = useLocalStorage('PAYMENT_UUID', '');

  const { restaurant_platform_id, number, coupon_info, ready_at, uuid: orderUuid } = order;

  const { data: menu } = useRestaurantMenuQuery({
    requestParams: { restaurantPlatformId: restaurant_platform_id },
  });

  const { data: restaurant } = useRestaurantQuery({
    requestParams: { restaurantPlatformId: restaurant_platform_id },
  });

  const orderItems = menu
    ? order.items.map((item) => {
        const menuItemsPicturesMap = getMenuPicturesMap(menu);

        return {
          ...item,
          imageUrl: menuItemsPicturesMap[item.uuid],
        };
      })
    : undefined;

  const [userEmail, setUserEmail] = useState<string>('');

  const { isError, isSuccess, mutate: submitReceiptForm, isLoading } = useSendCustomerEmailMutation();

  const canAskForReceipt = !coupon_info?.coupon_accepted;

  const handleSubmit: SubmitReceiptEventHandler = (email, isOptedIn) => {
    if (!canAskForReceipt) return;

    submitReceiptForm(
      {
        customerEmail: email,
        marketingConsent: isOptedIn,
        orderUuid,
        paymentUuid,
      },
      {
        onSuccess: (_, variables) => setUserEmail(variables.customerEmail),
        onSettled: () => {
          onClose();
        },
      }
    );
  };

  return (
    <AppLayout title={t`Your order`} showLogo>
      <Stack p={3} gap={3} pb="6rem" marginX="auto">
        <ReceiptStatus email={userEmail} isSuccess={isSuccess} isError={isError} />
        <OrderStatusCard number={number} status={orderStatus} readyAt={ready_at} />
        <IntercomLauncher />
        {restaurant && (
          <OrderInfosCard
            kitchenName={restaurant.restaurantLabel}
            kitchenAddress={restaurant.address}
            kitchenAccessInstructions={restaurant.kitchenInstructions}
            pickupTime={ready_at}
            orderPickupMap={<OrderPickupMap kitchenAddress={restaurant.address} />}
          />
        )}
        {canAskForReceipt ? (
          <Button size="lg" w="100%" colorScheme="gray" backgroundColor="gray.300" onClick={onOpen}>
            <Trans>Get receipt</Trans>
          </Button>
        ) : (
          <Text color="#979797" textAlign="center">
            <Trans>To obtain a receipt, please contact support@taster.com</Trans>
          </Text>
        )}
        {orderItems && <OrderDetailsCard items={orderItems} />}
      </Stack>
      <ReceiptModal
        isOpen={isOpen}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={isLoading}
        isReceiptSent={isSuccess}
      />
    </AppLayout>
  );
};
