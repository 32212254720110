import { Stack, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import pairingTerminal from '@/assets/anim/lottie_swap-machine.json';
import { LottieAnimation } from '@/components/LottieAnimation';

export const PairingTerminalLoadingContent = () => {
  return (
    <Stack gap={12} alignItems="center">
      <LottieAnimation animation={pairingTerminal} sx={{ width: '250px' }} />
      <Text fontWeight={500} fontSize={32}>
        <Trans>Pairing...</Trans>
      </Text>
    </Stack>
  );
};
