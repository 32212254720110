import { Box, Button, DrawerFooter, Flex, Spinner, Stack, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { ForwardedRef, forwardRef, useState } from 'react';

import { CouponInfoErrorTypeEnum } from '@/api/customer-order/orders';
import { useValidateOrderMutation } from '@/api/customer-order/orders/useValidateOrderMutation';
import { CouponError } from '@/api/types';
import { CouponForm } from '@/components/CouponForm/CouponForm';
import { useCart } from '@/contexts/cart';
import { formatCart } from '@/helpers/cart.helpers';
import useFormatPrice from '@/hooks/useFormatPrice';
import { Cart } from '@/types';

export interface CartDrawerFooterProps {
  currencyCode: string;
  languageCode: string;
  isError?: boolean;
  isLoading: boolean;
  isSubmitDisabled: boolean;
  onClose: () => unknown;
  onErrorClick: () => void;
  onSubmit: () => void;
  price: number;
  cart: Cart;
}

const MIN_CART_AMOUNT = 0.5;

const PROMO_AVAILABLE_COUNTRIES = new Set([]);

const CartDrawerFooter = forwardRef(
  (
    {
      currencyCode,
      isError,
      isLoading,
      cart,
      isSubmitDisabled,
      onClose,
      onErrorClick,
      languageCode,
      onSubmit,
      price,
    }: CartDrawerFooterProps,
    ref?: ForwardedRef<HTMLButtonElement>
  ) => {
    const [couponError, setCouponError] = useState<CouponError>();

    const { setCart } = useCart();

    const {
      mutate: submitCoupon,
      data: validatedOrderWithCoupon,
      isLoading: isCouponLoading,
      reset,
    } = useValidateOrderMutation({
      onSuccess: ({ couponInfo }) => {
        if (couponInfo?.couponAccepted) {
          setCart({ ...cart, couponCode: couponInfo.couponCode });
          if (couponError) setCouponError(undefined);
        } else setCouponError(couponInfo?.error);
      },
      onError: () => setCouponError({ errorType: CouponInfoErrorTypeEnum.BACKEND_ERROR }),
    });

    const { formatPrice } = useFormatPrice();

    const originalPrice = formatPrice(price, currencyCode);

    if (isError) {
      return (
        <DrawerFooter>
          <Stack>
            <Text>
              <Trans>Sorry, we encountered a problem. To checkout, please empty your cart and start again.</Trans>
            </Text>
            <Button onClick={onErrorClick}>
              <Trans>Empty cart</Trans>
            </Button>
          </Stack>
        </DrawerFooter>
      );
    }

    // const isCouponFormAvailable = PROMO_AVAILABLE_COUNTRIES.has(languageCode);
    const isCouponFormAvailable = false;

    const validateCoupon = ({ couponCode }: { couponCode: string }) => {
      if (!isCouponFormAvailable) return;

      submitCoupon({
        ...formatCart({
          ...cart,
          couponCode,
        }),
      });
    };

    const removeCoupon = () => {
      reset();

      setCouponError(undefined);
      setCart({ ...cart, couponCode: undefined });
    };

    const minStoreOrderAmount = formatPrice(MIN_CART_AMOUNT - price, currencyCode);

    return (
      <DrawerFooter borderTopWidth="1px" pb={6}>
        <Stack gap={2} width="100%">
          {isCouponFormAvailable && (
            <CouponForm
              isDisabled={isSubmitDisabled}
              isLoading={isCouponLoading}
              currencyCode={currencyCode}
              reductionAmount={validatedOrderWithCoupon?.couponInfo?.couponReduction}
              onRemove={removeCoupon}
              onSubmit={validateCoupon}
              couponError={couponError}
            />
          )}
          <Flex mb={4} fontSize="lg" fontWeight="500" justifyContent="space-between" alignItems="center">
            <Text as="span">
              <Trans>Total</Trans>
            </Text>
            {isLoading ? (
              <Spinner size="xs" />
            ) : validatedOrderWithCoupon?.couponInfo?.couponAccepted ? (
              <Flex gap={2}>
                <Text color="gray.400" textDecoration="line-through">
                  {originalPrice}
                </Text>
                <Text>{formatPrice(validatedOrderWithCoupon?.totalPrice, currencyCode)}</Text>
              </Flex>
            ) : (
              <Text>{originalPrice}</Text>
            )}
          </Flex>
          {price >= MIN_CART_AMOUNT ? (
            <Button
              ref={ref}
              w="100%"
              size="lg"
              isLoading={isLoading || isCouponLoading}
              isDisabled={isSubmitDisabled}
              onClick={onSubmit}
            >
              <Trans>Go to checkout</Trans>
            </Button>
          ) : (
            <Box>
              <Text color="red.error" fontWeight={500}>
                <Trans>Adjust cart to place order</Trans>
              </Text>
              <Text color="gray.disabled">
                <Trans>You are {minStoreOrderAmount} away from the store minimum</Trans>
              </Text>
              <Button ref={ref} w="100%" size="lg" onClick={onClose} mt={4}>
                <Trans>Add items</Trans>
              </Button>
            </Box>
          )}
        </Stack>
      </DrawerFooter>
    );
  }
);

CartDrawerFooter.displayName = 'CartDrawerFooter';

export default CartDrawerFooter;
