import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import { Alert, AlertDescription, Collapse, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

export interface ReceiptStatusProps {
  email?: string;
  isError: boolean;
  isSuccess: boolean;
}

export const ReceiptStatus = ({ email = '', isSuccess, isError }: ReceiptStatusProps) => {
  return (
    <>
      <Collapse animateOpacity in={isSuccess}>
        <Alert
          background="green.success.25"
          border="1px solid"
          borderColor="green.success.300"
          borderRadius={12}
          gap={3}
          padding={2}
          status="success"
          width="fit-content"
        >
          <CheckCircleIcon color="green.success.300" />
          <Text fontSize="sm" fontWeight={600} color="green.success.700">
            <Trans>Your receipt have been sent to {email}</Trans>
          </Text>
        </Alert>
      </Collapse>
      <Collapse animateOpacity in={isError}>
        <Alert
          background="red.error.25"
          border="1px solid"
          borderColor="red.error.300"
          borderRadius={12}
          gap={3}
          padding={2}
          status="error"
          width="fit-content"
        >
          <WarningIcon color="red.error.600" />
          <AlertDescription fontSize="sm" fontWeight={600} color="red.error.700">
            <Trans>Unable to send receipt.</Trans>
          </AlertDescription>
        </Alert>
      </Collapse>
    </>
  );
};
