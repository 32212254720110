import { Box, Heading } from '@chakra-ui/react';

import { SettingsAccessButton } from '@/components/SettingsAccessButton';

import { KioskBackButton } from './KioskBackButton';

const titleDescriptionStyle = {
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: '2',
  overflow: 'hidden',
};

export interface MenuItemsPageHeaderProps {
  title: string;
  onBackButtonClick: () => void;
}

export const MenuItemsPageHeader = ({ title, onBackButtonClick }: MenuItemsPageHeaderProps) => {
  return (
    <Box
      backgroundColor="#FFFFFF"
      position="sticky"
      top="0"
      zIndex={1}
      p={4}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      boxShadow="0px 4px 16px 0px #0000001A"
    >
      <KioskBackButton
        onClick={onBackButtonClick}
        sx={{
          height: '64px',
          width: '64px',
        }}
      />
      <Heading as="h4" fontSize={32} fontWeight="700" textAlign="center" maxW={'100%'} sx={titleDescriptionStyle}>
        {title}
      </Heading>
      <SettingsAccessButton />
    </Box>
  );
};
