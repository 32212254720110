import { useKeycloak } from '@react-keycloak/web';
import { useMemo } from 'react';

import { useTerminal, useUserLocation } from '@/hooks';

export const useOnSiteInfo = () => {
  const {
    keycloak: { authenticated },
  } = useKeycloak();
  const { userLocation } = useUserLocation();
  const { terminal } = useTerminal();

  const hasPairedTerminal = useMemo(() => !!(userLocation && terminal), [terminal, userLocation]);

  const authenticationPassPhrase = useMemo(
    () => `${userLocation?.locationUuid}-${terminal?.authorization?.keyId}:${terminal?.authorization?.key}`,
    [terminal, userLocation]
  );

  const isUserAuthenticated = useMemo(() => !!authenticated, [authenticated]);

  return {
    authenticationPassPhrase,
    hasPairedTerminal,
    isUserAuthenticated,
  };
};
