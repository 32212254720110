import { ArrowBackIcon } from '@chakra-ui/icons';
import { Button, ButtonProps, SystemStyleObject, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

interface BackButtonProps extends Pick<ButtonProps, 'isLoading' | 'loadingText'> {
  className?: string;
  label?: ReactNode;
  onClick?: () => void;
  sx?: SystemStyleObject;
  url?: string;
}

export const BackButton = ({ url, label, className, sx, onClick, ...buttonProps }: BackButtonProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      url ? navigate(url) : navigate(-1);
    }
  };

  return (
    <Button
      variant="ghost"
      onClick={handleClick}
      className={className}
      sx={sx}
      colorScheme="gray"
      leftIcon={<ArrowBackIcon />}
      {...buttonProps}
    >
      {!!label && <Text ml={2}>{label}</Text>}
    </Button>
  );
};
