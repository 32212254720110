import { Avatar, Stack, Text } from '@chakra-ui/react';
import { useKeycloak } from '@react-keycloak/web';
import { useQuery } from '@tanstack/react-query';

import TasterIcon from '@/assets/img/taster_circle_logo.svg';

interface UserInfoProps {
  kitchenLabel: string;
}

export const UserInfo = ({ kitchenLabel }: UserInfoProps) => {
  const { keycloak } = useKeycloak();

  const { data: userInfo } = useQuery<{ email?: string }, void>(['loadUserInfo'], () => keycloak.loadUserInfo());

  return (
    <Stack alignItems="center">
      <Avatar
        src={TasterIcon}
        sx={{
          background: 'white',
          border: `1px solid`,
          borderColor: 'gray.300',
          width: '96px',
          height: '96px',
        }}
      />
      <Text fontWeight={700} fontSize={24} color="gray.700">
        {kitchenLabel}
      </Text>
      {!!userInfo?.email && (
        <Text fontSize={20} color="gray.600">
          {userInfo.email}
        </Text>
      )}
    </Stack>
  );
};
