import * as Sentry from '@sentry/react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { ProtectedRoute } from '@/components/ProtectedRoute';
import { TerminalProvider } from '@/contexts';
import { MenuDataProvider } from '@/contexts/MenuDataProvider';
import { useOnSiteInfo } from '@/hooks/useOnSiteInfo';
import { TerminalSettingsPage } from '@/routes';
import { CartPage } from '@/routes/CartPage';
import { CheckoutPage } from '@/routes/CheckoutPage';
import ConfirmPaymentPage from '@/routes/ConfirmPaymentPage';
import { HomePage } from '@/routes/HomePage';
import { AddItemPage } from '@/routes/Kiosk/AddItemPage';
import { LocationFinderPage } from '@/routes/LocationFinderPage';
import { MenuPage } from '@/routes/MenuPage';
import NotFoundPage from '@/routes/NotFoundPage';
import { OrderStatusPage } from '@/routes/OrderStatusPage';
import { OrderSummaryPage } from '@/routes/OrderSummaryPage';
import {
  CustomerNamePage,
  InternalLocationsPage,
  MenuCategoriesPage,
  MenuItemsPage,
  OrderConfirmationPage,
  PairTerminalPage,
  PaymentInstructionsPage,
} from '@/routes/PayOnSite';
import { RestaurantsPage } from '@/routes/RestaurantsPage';
import { TerminalDisconnectedPage } from '@/routes/TerminalDisconnected';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const AppRouter = () => {
  const { hasPairedTerminal } = useOnSiteInfo();

  return (
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <SentryRoutes>
          <Route path="/" element={hasPairedTerminal ? <Navigate to="/onSite" replace /> : <LocationFinderPage />} />
          <Route path="/:locationUuid" element={<HomePage />} />
          <Route path="/menu/:restaurantPlatformId/:cityName?/:extraInfo?" element={<MenuPage />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/confirmPayment" element={<ConfirmPaymentPage />} />
          <Route path="/orderStatus/:orderUuid" element={<OrderStatusPage />} />
          <Route path="/order/:orderUuid" element={<OrderSummaryPage />} />
          <Route path="/notFound" element={<NotFoundPage />} />
          <Route path="*" element={<NotFoundPage />} />
          {/* OnSite flow router */}
          <Route path="/onSite" element={<ProtectedRoute />}>
            <Route
              index
              element={hasPairedTerminal ? <Navigate to="terminalSettings" replace /> : <InternalLocationsPage />}
            />
            <Route path="/onSite/internalLocations" element={<InternalLocationsPage />} />
            <Route path="/onSite/pairTerminal" element={<PairTerminalPage />} />
            <Route path="/onSite/terminalSettings" element={<TerminalSettingsPage />} />
            <Route element={<TerminalProvider />}>
              <Route path="/onSite/:locationUuid" element={<RestaurantsPage />} />
              <Route element={<MenuDataProvider />}>
                <Route path="/onSite/menu/:restaurantPlatformId" element={<MenuCategoriesPage />} />
                <Route path="/onSite/menu/:restaurantPlatformId/categories/:categoryUuid" element={<MenuItemsPage />} />
                <Route
                  path="/onSite/menu/:restaurantPlatformId/categories/:categoryUuid/item/:menuElementUuid"
                  element={<AddItemPage />}
                />
                <Route path="/onSite/menu/:restaurantPlatformId/cart" element={<CartPage />} />
              </Route>
              <Route path="/onSite/:restaurantPlatformId/customerName" element={<CustomerNamePage />} />
              <Route
                path="/onSite/:restaurantPlatformId/paymentInstructions/:orderUuid"
                element={<PaymentInstructionsPage />}
              />
              <Route path="/onSite/orderConfirmed/:orderUuid" element={<OrderConfirmationPage />} />
            </Route>
            <Route path="/onSite/terminalDisconnected" element={<TerminalDisconnectedPage />} />
            <Route path="/onSite/notFound" element={<NotFoundPage />} />
            <Route path="/onSite/*" element={<NotFoundPage />} />
          </Route>
        </SentryRoutes>
      </QueryParamProvider>
    </BrowserRouter>
  );
};

export default AppRouter;
