import { useLocalStorage } from '@uidotdev/usehooks';

import { InitializationBundle } from '@/api/gateway-click-collect/terminals';

export const useTerminal = () => {
  const [terminal, setTerminal] = useLocalStorage<InitializationBundle | null>('terminal', null);

  const resetTerminal = () => {
    setTerminal(null);
  };

  return {
    resetTerminal,
    setTerminal,
    terminal,
  };
};
