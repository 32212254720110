import { Icon, keyframes } from '@chakra-ui/react';
import { FiRefreshCcw } from 'react-icons/fi';

export interface RefreshIconProps {
  isRefreshing?: boolean;
}

const rotateKeyframes = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
`;

export const RefreshIcon = ({ isRefreshing = false }: RefreshIconProps) => {
  return <Icon as={FiRefreshCcw} animation={isRefreshing ? `${rotateKeyframes} 2s linear infinite` : undefined} />;
};
