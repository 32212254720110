import { Box, Button, chakra, Flex, Heading, Image } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useKeycloak } from '@react-keycloak/web';
import { ReactNode } from 'react';

import LogOutIcon from '@/assets/img/logout_icon.svg';
import TasterIcon from '@/assets/img/taster_circle_logo.svg';
import { BackButton } from '@/components/BackButton';
import { SettingsAccessButton } from '@/components/SettingsAccessButton';
import { useTerminal, useUserLocation } from '@/hooks';

const StyledHeading = chakra(Heading, {
  baseStyle: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
    overflow: 'hidden',
    fontSize: { sm: 32 },
    fontWeight: '500',
    position: 'absolute',
    textAlign: 'center',
    left: 0,
    right: 0,
  },
});

interface PairDeviceLayoutProps {
  children: ReactNode;
  headerTitle: string;
  hasLogOutButton?: boolean;
  hasSettingsButton?: boolean;
  hasBackButton?: boolean;
  url?: string;
  backButtonText?: string;
}

export const PairDeviceLayout = ({
  children,
  headerTitle,
  backButtonText,
  hasLogOutButton = false,
  hasSettingsButton = false,
  hasBackButton = false,
  url,
}: PairDeviceLayoutProps) => {
  const {
    keycloak: { logout },
  } = useKeycloak();

  const { resetUserlocation } = useUserLocation();
  const { resetTerminal } = useTerminal();

  const logOutInternalUser = () => {
    resetUserlocation();
    resetTerminal();
    logout();
  };

  return (
    <Box position="relative" height="100%">
      <Box position="sticky" top="0" zIndex={1}>
        <Flex
          backgroundColor="#FFFFFF"
          px={4}
          py={6}
          boxShadow="0px 4px 8px rgba(0, 0, 0, 0.03)"
          justify="space-between"
          alignItems="center"
        >
          {hasBackButton ? <BackButton label={backButtonText} url={url} /> : <Image src={TasterIcon} height="45" />}
          <StyledHeading as="h4" width="fit-content" mx="auto" fontSize={24}>
            {headerTitle}
          </StyledHeading>
          {hasSettingsButton && (
            <SettingsAccessButton sx={{ position: 'absolute', right: 4, top: '50%', transform: 'translateY(-50%)' }} />
          )}
          {hasLogOutButton && (
            <Button
              variant="ghost"
              colorScheme="gray"
              onClick={logOutInternalUser}
              leftIcon={<Image src={LogOutIcon} height={4} width={4} />}
            >
              <Trans>Log out</Trans>
            </Button>
          )}
        </Flex>
      </Box>
      <Box py={10}>{children}</Box>
    </Box>
  );
};
