import { HStack, Stack, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import Card from '@/components/Card';

export interface DeviceInfoProps {
  deviceId: string;
  cardMachineId: string;
}

export const DeviceInfo = ({ deviceId, cardMachineId }: DeviceInfoProps) => {
  return (
    <Card padding={6}>
      <Stack width="100%">
        <HStack justifyContent="space-between">
          <Text fontSize={20}>
            <Trans>Device ID</Trans>
          </Text>
          <Text fontSize={20}>{deviceId}</Text>
        </HStack>
        <HStack justifyContent="space-between">
          <Text fontSize={20}>
            <Trans>Card machine ID</Trans>
          </Text>
          <Text textAlign="end" fontSize={20}>
            {cardMachineId}
          </Text>
        </HStack>
      </Stack>
    </Card>
  );
};
