import { Box, Button, Heading, Image, Stack, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useQueryClient } from '@tanstack/react-query';
import { keyBy } from 'lodash-es';
import { ReactNode } from 'react';
import { MdRefresh } from 'react-icons/md';
import { useLocation } from 'react-router-dom';

import { ConceptInformation, useConceptsConfigurationQuery } from '@/api/gateway-click-collect/configurations';
import { UserLocation } from '@/api/gateway-click-collect/locations';
import { Restaurant, useRestaurantQueryOptions, useRestaurantsQuery } from '@/api/gateway-click-collect/restaurants';
import { InitializationBundle } from '@/api/gateway-click-collect/terminals';
import MoonLogo from '@/assets/img/moon.svg';
import TasterLogo from '@/assets/img/taster-logo-black.svg';
import { ErrorScreen } from '@/components/ErrorScreen';
import PageLoader from '@/components/PageLoader';
import { SettingsAccessButton } from '@/components/SettingsAccessButton';
import { TouchToStartModal } from '@/components/TouchToStartModal';
import { HOUR_MS, MINUTE_MS } from '@/constants';
import { useTerminalContext } from '@/contexts';
import { useOnSiteSession } from '@/contexts/onSiteSession';
import { getSortedRestaurantsWithConcept } from '@/helpers/brands.helpers';
import dataLayer from '@/helpers/dataLayer.helpers';
import { usePollingTerminalStatus } from '@/hooks/usePollingTerminalStatus';
import { useBackgroundQueries } from '@/routes/RestaurantsPage/useBackgroundQueries';
import { RestaurantWithConcept } from '@/types';

import { RestaurantCard } from './RestaurantCard';

interface LayoutProps {
  children: ReactNode;
  title: ReactNode;
}

const Layout = ({ children, title }: LayoutProps) => {
  return (
    <Stack height="100%" spacing={0}>
      <Box
        as="header"
        display="grid"
        gridTemplateColumns="1fr auto 1fr"
        borderBottom="1px"
        borderColor="#D9D9D9"
        boxShadow="md"
        paddingX={2}
        paddingY={6}
      >
        <Heading as="h1" textAlign="center" size="lg" marginX="auto" gridColumnStart={2}>
          {title}
        </Heading>
        <SettingsAccessButton sx={{ justifySelf: 'end' }} />
      </Box>
      {children}
    </Stack>
  );
};

const getRestaurantWithConcepts = ({
  restaurants,
  conceptsInformation,
}: {
  restaurants?: Restaurant[];
  conceptsInformation?: ConceptInformation[];
}) => {
  if (!restaurants?.length || !conceptsInformation?.length) {
    return [];
  }

  const conceptsInformationByUuid = keyBy(conceptsInformation, 'uuid');

  return restaurants
    .filter(({ status }) => status === 'open')
    .map(({ conceptUuid, ...restaurant }) => {
      const concept = conceptsInformationByUuid[conceptUuid];

      if (concept) {
        return {
          concept,
          ...restaurant,
        };
      }
    })
    .filter((conceptRestaurant): conceptRestaurant is RestaurantWithConcept => !!conceptRestaurant);
};

interface RestaurantsListProps {
  conceptsInformation?: ConceptInformation[];
  isError: boolean;
  isLoading: boolean;
  restaurants?: Restaurant[];
  seedRestaurantCache: (restaurantPlatformId: string) => void;
  terminal: InitializationBundle;
  userLocation: UserLocation;
}

const RestaurantsList = ({
  conceptsInformation,
  isError,
  isLoading,
  restaurants,
  seedRestaurantCache,
  terminal,
  userLocation,
}: RestaurantsListProps) => {
  const location = useLocation();
  const { startOnSiteSession } = useOnSiteSession();
  const { kitchenLabel } = userLocation;
  const { terminalUuid } = terminal.terminalInfo;

  const restaurantWithConcepts = getRestaurantWithConcepts({ conceptsInformation, restaurants });

  const sortedRestaurants = getSortedRestaurantsWithConcept(restaurantWithConcepts);

  if (sortedRestaurants.length) {
    return (
      <Layout title={<Trans>Choose a brand to see the menu and order</Trans>}>
        <Box overflow="auto">
          <Box
            marginX={{ base: 0, lg: '15%' }}
            display="grid"
            justifyContent="center"
            gridTemplateColumns={{
              base: 'minmax(0, 768px)',
              lg: 'minmax(0, 768px)',
              md: 'repeat(2, minmax(0, 768px))',
            }}
            gap={{ base: 6, lg: 10 }}
            padding={{ base: 6, lg: 10 }}
          >
            {sortedRestaurants.map(
              ({ restaurantPlatformId, concept: { conceptName, heroPictures, logoPictures, tags } }) => {
                return (
                  <RestaurantCard
                    conceptName={conceptName}
                    heroPicture={heroPictures.find(({ width }) => width === 768)?.url}
                    key={restaurantPlatformId}
                    logoPicture={logoPictures?.[0].url}
                    restaurantPlatformId={restaurantPlatformId}
                    tags={tags}
                    onClick={(restaurantPlatformId: string) => {
                      seedRestaurantCache(restaurantPlatformId);
                      dataLayer.logEvent('select_concept-click', {
                        brand_label: conceptName,
                        kitchen_label: kitchenLabel,
                        order_channel: 'onsite',
                      });
                    }}
                  />
                );
              }
            )}
          </Box>
        </Box>
        <TouchToStartModal
          defaultIsOpen={!!location.state?.startSession}
          onClose={() => {
            startOnSiteSession({ kitchenLabel, terminalUuid });
          }}
        />
      </Layout>
    );
  }

  if (isLoading) {
    return <PageLoader />;
  }

  if (isError) {
    return (
      <Layout title={<Trans>Our restaurants</Trans>}>
        <ErrorScreen
          button={
            <Button
              colorScheme="black"
              fontWeight={700}
              leftIcon={<MdRefresh />}
              onClick={() => window.location.reload()}
              size="lg"
              textTransform="uppercase"
              variant="solid"
            >
              <Trans>Refresh</Trans>
            </Button>
          }
          text={
            <Heading as="p" size="lg" fontWeight={700} textAlign="center">
              <Trans>Sorry, we’ve encountered an error. Please refresh the page.</Trans>
            </Heading>
          }
          sx={{ height: '100%' }}
        />
      </Layout>
    );
  }

  return (
    <Layout title={<Trans>Our restaurants</Trans>}>
      <Stack alignItems="center" justifyContent="space-evenly" height="100%" paddingBlock={8} paddingX={4}>
        <Stack justifyContent="center" alignItems="center" gap={4} flex={4}>
          <Image src={MoonLogo} width={{ base: 200, md: 300 }} />
          <Text textAlign="center" fontWeight={700} fontSize={{ base: '2xl', md: '4xl' }}>
            <Trans>All our restaurants are closed</Trans>
          </Text>
        </Stack>
        <Image alt="Taster logo" src={TasterLogo} width={{ base: 200, md: 400 }} flex={1} />
      </Stack>
    </Layout>
  );
};

export const RestaurantsPage = () => {
  const queryClient = useQueryClient();
  const { userLocation, terminal } = useTerminalContext();
  const { locationUuid } = userLocation;
  const { terminalInfo } = terminal;

  usePollingTerminalStatus({
    locationUuid,
    terminalUuid: terminalInfo.terminalUuid,
    maxTry: 5,
  });

  const {
    data: conceptConfigs,
    isLoading: isLoadingConceptConfigs,
    isError: isConceptConfigsError,
  } = useConceptsConfigurationQuery({
    options: {
      cacheTime: Infinity,
      refetchInterval: HOUR_MS * 3,
    },
  });

  const {
    data: restaurants,
    isLoading: isLoadingRestaurants,
    isError: isRestaurantsError,
  } = useRestaurantsQuery({
    options: {
      cacheTime: Infinity,
      refetchInterval: MINUTE_MS * 5,
    },
    requestParams: {
      locationUuid,
      withOpeningInformation: true,
    },
  });

  useBackgroundQueries({ restaurants });

  const isLoading = isLoadingConceptConfigs || isLoadingRestaurants;
  const isError = isConceptConfigsError || isRestaurantsError;

  const seedRestaurantCache = (restaurantPlatformId: string) => {
    const restaurant = restaurants?.find((restaurant) => restaurant.restaurantPlatformId === restaurantPlatformId);

    if (restaurant) {
      queryClient.setQueryData(
        useRestaurantQueryOptions.queryKey({ restaurantPlatformId, withOpeningInformation: true }),
        () => restaurant
      );
    }
  };

  return (
    <RestaurantsList
      conceptsInformation={conceptConfigs?.concepts}
      isError={isError}
      isLoading={isLoading}
      restaurants={restaurants}
      seedRestaurantCache={seedRestaurantCache}
      terminal={terminal}
      userLocation={userLocation}
    />
  );
};
