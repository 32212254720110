import { Button, ButtonProps, Progress, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useCallback, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCart } from '@/contexts/cart';

export interface NextCustomerProgressBarProps extends Omit<ButtonProps, 'onClick'> {
  locationUuid: string;
  onNextCustomer: () => void;
}

export const NextCustomerProgressBar = ({
  locationUuid,
  onNextCustomer = () => {},
  ...buttonProps
}: NextCustomerProgressBarProps) => {
  const navigate = useNavigate();
  const [countdown, incrementCountdown] = useReducer((count) => count + 1, 0);
  const { resetCart } = useCart();

  const goToHomePage = useCallback(() => {
    onNextCustomer();
    resetCart();
    navigate(`/onSite/${locationUuid}`, { state: { startSession: true } });
  }, [onNextCustomer, resetCart, navigate, locationUuid]);

  useEffect(() => {
    let nextCustomerInterval: ReturnType<typeof setInterval>;

    if (countdown >= 6000) {
      goToHomePage();
    } else {
      nextCustomerInterval = setInterval(() => {
        incrementCountdown();
      }, 10);
    }

    return () => clearInterval(nextCustomerInterval);
  }, [goToHomePage, countdown]);

  return (
    <Button
      border="1px solid #D0D5DD"
      borderRadius={8}
      colorScheme="gray"
      minWidth="300px"
      onClick={goToHomePage}
      position="relative"
      size="xl"
      {...buttonProps}
    >
      <Text zIndex={1}>
        <Trans>Next customer</Trans>
      </Text>
      <Progress
        backgroundColor="white"
        borderRadius={6}
        value={countdown / 60}
        colorScheme="gray.300"
        height="100%"
        position="absolute"
        width="100%"
      />
    </Button>
  );
};
