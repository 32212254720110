import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { useTasterApis } from '@/api/TasterApiContext';
import { useOnSiteInfo } from '@/hooks/useOnSiteInfo';

import { PrepareTerminalOrderRequest, ReadiedOrderSummary, ResponseError } from './types';

export const usePrepareTerminalOrderMutation = <TContext = unknown>(
  options?: Omit<
    UseMutationOptions<ReadiedOrderSummary, ResponseError, PrepareTerminalOrderRequest, TContext>,
    'mutationFn'
  >
) => {
  const { authenticationPassPhrase } = useOnSiteInfo();
  const {
    gatewayClickCollect: { ordersApi },
  } = useTasterApis();

  return useMutation({
    ...options,
    mutationFn: (requestParams) => {
      return ordersApi.prepareTerminalOrder(requestParams, async ({ init }) => ({
        ...init,
        headers: {
          ...init.headers,
          Authorization: `Basic ${window.btoa(authenticationPassPhrase)}`,
        },
      }));
    },
  });
};
