import * as gatewayClickCollect from '@tasterkitchens/client-ts-gateway-click-collect-v1';

import ApiError from '@/api/ApiError';

export type GatewayClickCollect = {
  configurationsApi: gatewayClickCollect.ConfigurationApi;
  locationsApi: gatewayClickCollect.LocationsApi;
  ordersApi: gatewayClickCollect.OrdersApi;
  restaurantsApi: gatewayClickCollect.RestaurantsApi;
  terminalsApi: gatewayClickCollect.TerminalsApi;
};

export const initializeGatewayClickCollect = (getAccessToken: () => string) => {
  const apiConfiguration = new gatewayClickCollect.Configuration({
    accessToken: getAccessToken,
    basePath: import.meta.env.VITE_GATEWAY_CLICK_COLLECT_API,
  });

  const configurationsApi = new gatewayClickCollect.ConfigurationApi(apiConfiguration);
  const locationsApi = new gatewayClickCollect.LocationsApi(apiConfiguration);
  const ordersApi = new gatewayClickCollect.OrdersApi(apiConfiguration);
  const restaurantsApi = new gatewayClickCollect.RestaurantsApi(apiConfiguration);
  const terminalsApi = new gatewayClickCollect.TerminalsApi(apiConfiguration);

  return {
    configurationsApi,
    locationsApi,
    ordersApi,
    restaurantsApi,
    terminalsApi,
  };
};
