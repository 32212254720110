import { Button, FormControl, FormHelperText, FormLabel, Heading, Input, Stack } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import announceAnimation from '@/assets/anim/lottie_announcement.json';
import { LottieAnimation } from '@/components/LottieAnimation';

interface CustomerNameFormProps {
  isSubmitting?: boolean;
  onSubmit: (value: string) => void;
}

export const CustomerNameForm = ({ isSubmitting = false, onSubmit }: CustomerNameFormProps) => {
  const { handleSubmit, control, setFocus } = useForm({
    defaultValues: {
      customerName: '',
    },
  });

  useEffect(() => {
    setFocus('customerName');
  }, [setFocus]);

  return (
    <Stack>
      <LottieAnimation animation={announceAnimation} sx={{ width: '250px', alignSelf: 'center' }} />
      <Stack
        as="form"
        gap={6}
        onSubmit={handleSubmit((formData) => {
          onSubmit(formData.customerName);
        })}
      >
        <Controller
          control={control}
          name="customerName"
          rules={{ required: true }}
          render={({ field, fieldState }) => {
            const { invalid } = fieldState;

            return (
              <FormControl isInvalid={invalid}>
                <FormLabel as={Heading} fontWeight={700} fontSize={32}>
                  <Trans>Your name?</Trans>
                </FormLabel>
                <Input {...field} variant="outline" backgroundColor="white" />
                <FormHelperText>
                  <Trans>Just so we can shout your name when the order is ready</Trans>
                </FormHelperText>
              </FormControl>
            );
          }}
        />
        <Button
          isLoading={isSubmitting}
          loadingText={<Trans>Submit</Trans>}
          size="lg"
          sx={{ alignSelf: 'flex-start' }}
          type="submit"
        >
          <Trans>Submit</Trans>
        </Button>
      </Stack>
    </Stack>
  );
};
