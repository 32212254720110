import { Alert, AlertDescription, AlertTitle, Icon, Stack, Text } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import { useEffect } from 'react';
import { MdErrorOutline } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';

import { OrderStatusEnum, ReadiedOrderSummary, useOrderQuery } from '@/api/gateway-click-collect/orders';
import terminalAnimation from '@/assets/anim/lottie_terminal.json';
import { LottieAnimation } from '@/components/LottieAnimation';
import { useTerminalContext } from '@/contexts';
import { useCart } from '@/contexts/cart';
import dataLayer from '@/helpers/dataLayer.helpers';

import { PairDeviceLayout } from '../PairDeviceLayout';
import { CancelOrderButton } from './CancelOrderButton';
import { CancelPaymentButton } from './CancelPaymentButton';

const isOrderValid = (order: ReadiedOrderSummary) => {
  const validOrderStatus: OrderStatusEnum[] = [
    OrderStatusEnum.Accepted,
    OrderStatusEnum.Created,
    OrderStatusEnum.Delivered,
    OrderStatusEnum.PaymentSucceeded,
    OrderStatusEnum.ReadyForDispatch,
  ];

  return validOrderStatus.includes(order.status);
};

export const PaymentInstructionsPage = () => {
  const navigate = useNavigate();
  const { restaurantPlatformId, orderUuid } = useParams() as { restaurantPlatformId: string; orderUuid: string };
  const { terminal, userLocation } = useTerminalContext();
  const { terminalUuid, locationUuid } = terminal.terminalInfo;
  const { cart } = useCart();

  const { data: order } = useOrderQuery({
    requestParams: {
      orderUuid,
    },
    options: {
      refetchInterval: (_data, context) => {
        if (context.state.fetchFailureCount > 3) {
          return false;
        }

        return 1000;
      },
    },
  });

  const isPaymentFailed = order?.status === OrderStatusEnum.PaymentFailed;

  useEffect(() => {
    if (order && isOrderValid(order)) {
      dataLayer.logPaymentSuccess({
        kitchenLabel: userLocation.kitchenLabel,
        order,
        orderChannel: 'onsite',
      });
      navigate(`/onSite/orderConfirmed/${order.uuid}`);
    }
  }, [cart, navigate, order, userLocation]);

  return (
    <PairDeviceLayout headerTitle={t`Checkout`}>
      <Stack px={2} alignItems="center" position="relative" width="100%" height="100%">
        <CancelPaymentButton
          locationUuid={locationUuid}
          order={order}
          restaurantPlatformId={restaurantPlatformId}
          terminalUuid={terminalUuid}
          sx={{
            left: '16px',
            position: 'absolute',
            top: 0,
            transform: 'translateY(-50%)',
          }}
        />
        <Stack gap={12} alignItems="center">
          <LottieAnimation animation={terminalAnimation} sx={{ width: '250px' }} />
          <Stack gap={8}>
            <Text fontWeight={700} fontSize={32} maxWidth={400} textAlign="center">
              <Trans>Follow the instructions on the card machine </Trans>
            </Text>
            {isPaymentFailed && (
              <Alert
                background="red.error.25"
                border="1px solid"
                borderColor="red.error.300"
                borderRadius={12}
                padding={4}
                status="error"
                variant="subtle"
              >
                <Icon as={MdErrorOutline} color="red.error.600" width={8} height={8} mr={3} />
                <Stack gap={2}>
                  <AlertTitle color="red.error.700" fontSize="xl">
                    <Trans>Payment failed</Trans>
                  </AlertTitle>
                  <AlertDescription color="red.error.700" fontSize="lg">
                    <Trans>Your card wasn&apos;t charged. Please try again.</Trans>
                  </AlertDescription>
                </Stack>
              </Alert>
            )}
          </Stack>
          <CancelOrderButton
            locationUuid={locationUuid}
            order={order}
            terminalUuid={terminalUuid}
            sx={{
              background: 'white',
              border: '1px solid',
              borderColor: 'gray.300',
              color: 'gray.700',
            }}
          />
        </Stack>
      </Stack>
    </PairDeviceLayout>
  );
};
