import { useKeycloak } from '@react-keycloak/web';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useOnSiteInfo } from '@/hooks/useOnSiteInfo';

export const ProtectedRoute = () => {
  const { isUserAuthenticated } = useOnSiteInfo();
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (!isUserAuthenticated) {
      keycloak.login();
    }
  }, [isUserAuthenticated, keycloak]);

  return <Outlet />;
};
