import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ApiError from '@/api/ApiError';
import { useCancelActionMutation, useTerminalMutation } from '@/api/gateway-click-collect/terminals';
import { TerminalStatusEnum } from '@/api/types';
import { useOnSiteInfo } from '@/hooks/useOnSiteInfo';

export interface UsePollingTerminalStatusProps {
  delay?: number;
  locationUuid: string;
  maxTry?: number;
  terminalUuid: string;
}

export const usePollingTerminalStatus = ({
  delay = 2000,
  locationUuid,
  maxTry = 3,
  terminalUuid,
}: UsePollingTerminalStatusProps) => {
  const navigate = useNavigate();
  const { hasPairedTerminal } = useOnSiteInfo();
  const [busyStatusCount, setBusyStatusCount] = useState(0);
  const [failureStatusCount, setFailureStatusCount] = useState(0);

  const { mutateAsync } = useTerminalMutation();
  const { mutateAsync: deleteOngoingPayment } = useCancelActionMutation();

  const pollingTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const checkTerminalStatus = useCallback(async () => {
    clearTimeout(pollingTimeoutRef.current);

    if (hasPairedTerminal) {
      try {
        const terminalStatusResponse = await mutateAsync({ locationUuid, terminalUuid });

        if (terminalStatusResponse.status === TerminalStatusEnum.BUSY && busyStatusCount < maxTry) {
          setBusyStatusCount(busyStatusCount + 1);

          try {
            await deleteOngoingPayment({ locationUuid, terminalUuid });
          } catch (error) {
            if (error instanceof ApiError && (error.status === 400 || error.status === 409)) {
              pollingTimeoutRef.current = setTimeout(() => checkTerminalStatus(), delay);
            } else {
              navigate('/onSite/terminalDisconnected');
            }
          }
        } else if (terminalStatusResponse.status === TerminalStatusEnum.OFFLINE) {
          navigate('/onSite/terminalDisconnected');
        }
      } catch (error) {
        if (error instanceof ApiError && (error.status === 410 || error.status >= 500) && failureStatusCount < maxTry) {
          setFailureStatusCount(failureStatusCount + 1);
          pollingTimeoutRef.current = setTimeout(() => checkTerminalStatus(), delay);
        } else {
          navigate('/onSite/terminalDisconnected');
        }
      }
    }
  }, [
    hasPairedTerminal,
    mutateAsync,
    locationUuid,
    terminalUuid,
    busyStatusCount,
    maxTry,
    deleteOngoingPayment,
    delay,
    navigate,
    failureStatusCount,
  ]);

  useEffect(() => {
    checkTerminalStatus();

    return () => clearTimeout(pollingTimeoutRef.current);
  }, [checkTerminalStatus]);
};
