import { Button, Heading, Stack } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import { MouseEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';

import { PairingTerminalErrorEnum } from '@/api/types';
import warningAnimation from '@/assets/anim/lottie_attention.json';
import { LottieAnimation } from '@/components/LottieAnimation';

export interface PairingTerminalFailureContentProps {
  errorStatus: PairingTerminalErrorEnum | string;
  handleRetryClick: MouseEventHandler<HTMLButtonElement>;
}

export const PairingTerminalFailureContent = ({
  errorStatus,
  handleRetryClick,
}: PairingTerminalFailureContentProps) => {
  const PairingErrorTranslationMap: Record<string, string> = {
    [PairingTerminalErrorEnum.addressUnavailable]: t`Kitchen address isn't formatted correctly. Please contact support.`,
    [PairingTerminalErrorEnum.locationInactivated]: t`No click and collect restaurant are currently activated for this kitchen.`,
  };

  const navigate = useNavigate();

  return (
    <Stack gap={24} alignItems="center">
      <Heading>{PairingErrorTranslationMap[errorStatus] ?? <Trans>Pairing failed</Trans>}</Heading>
      <LottieAnimation animation={warningAnimation} />
      <Stack my={2} gap={4} w="100%" alignItems="center">
        <Button width="100%" maxWidth={220} paddingY={8} fontSize={24} onClick={handleRetryClick}>
          <Trans>Retry</Trans>
        </Button>
        <Button
          width="100%"
          maxWidth={220}
          paddingY={8}
          fontSize={24}
          onClick={() => {
            navigate('/onSite');
          }}
        >
          <Trans>Go back</Trans>
        </Button>
      </Stack>
    </Stack>
  );
};
