import { Button, Icon, SystemStyleObject } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { BsLink } from 'react-icons/bs';
import { Link } from 'react-router-dom';

export interface PairTerminalButtonProps {
  sx?: SystemStyleObject;
}

export const PairTerminalButton = ({ sx }: PairTerminalButtonProps) => {
  return (
    <Button
      as={Link}
      backgroundColor="white"
      colorScheme="gray"
      leftIcon={<Icon as={BsLink} sx={{ fontSize: 18 }} />}
      sx={sx}
      to="/onSite/internalLocations"
      variant="outline"
    >
      <Trans>Pair card machine</Trans>
    </Button>
  );
};
