import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import ApiError from '@/api/ApiError';
import { useTasterApis } from '@/api/TasterApiContext';

import { locationsKeys } from './locations.helpers';
import { UserLocation } from './types';

export const useUserLocationsQueryOptions = {
  queryKey: () => [...locationsKeys.all, 'listUserLocations'],
};

export interface UseUserLocationsQueryProps<TData> {
  options?: Omit<
    UseQueryOptions<UserLocation[], ApiError, TData, ReturnType<typeof useUserLocationsQueryOptions.queryKey>>,
    'queryFn' | 'queryKey'
  >;
}

export const useUserLocationsQuery = <TData = UserLocation[]>({ options }: UseUserLocationsQueryProps<TData> = {}) => {
  const {
    gatewayClickCollect: { locationsApi },
  } = useTasterApis();

  return useQuery({
    ...options,
    queryFn: () => locationsApi.getUserLocations(),
    queryKey: useUserLocationsQueryOptions.queryKey(),
  });
};
