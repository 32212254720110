import { createContext, useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { UserLocation } from '@/api/gateway-click-collect/locations';
import { InitializationBundle } from '@/api/gateway-click-collect/terminals';
import { useTerminal, useUserLocation } from '@/hooks';

export interface TerminalContextProps {
  terminal: InitializationBundle;
  userLocation: UserLocation;
}

const TerminalContext = createContext<TerminalContextProps | null>(null);

export const TerminalProvider = () => {
  const { terminal } = useTerminal();
  const { userLocation } = useUserLocation();

  if (!userLocation || !terminal) {
    return <Navigate to="/onSite/terminalDisconnected" />;
  }

  return (
    <TerminalContext.Provider value={{ terminal, userLocation }}>
      <Outlet />
    </TerminalContext.Provider>
  );
};

export const useTerminalContext = () => {
  const terminalContext = useContext(TerminalContext);

  if (!terminalContext) {
    throw new Error('Make sure you are using useTerminalContext within a TerminalProvider');
  }

  return terminalContext;
};
