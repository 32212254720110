import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import { ReceiptForm, SubmitReceiptEventHandler } from './ReceiptForm';

interface ReceiptModalProps {
  isLoading: boolean;
  isOpen: boolean;
  isReceiptSent: boolean;
  onClose: () => void;
  onSubmit: SubmitReceiptEventHandler;
}

export const ReceiptModal = ({ isLoading, isOpen, onSubmit, onClose }: ReceiptModalProps) => {
  const modalSize = useBreakpointValue<'xs' | 'md'>({ base: 'xs', md: 'md' });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={modalSize}>
      <ModalOverlay />
      <ModalContent borderRadius="3xl">
        <ModalCloseButton top={4} />
        <ModalHeader borderBottomWidth="2px" textAlign="center">
          <Trans>Get receipt</Trans>
        </ModalHeader>
        <ModalBody paddingY={4}>
          <ReceiptForm onSubmit={onSubmit} isLoading={isLoading} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
