import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  Collapse,
  Flex,
  FormControl,
  FormHelperText,
  Input,
  InputGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { t, Trans } from '@lingui/macro';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useSetOrderEmailMutation } from '@/api/gateway-click-collect/orders';

export interface OrderReceiptFormProps {
  orderUuid: string;
}

export const OrderReceiptForm = ({ orderUuid }: OrderReceiptFormProps) => {
  const { control, handleSubmit } = useForm({
    defaultValues: { customerEmail: '' },
    resolver: yupResolver(yup.object({ customerEmail: yup.string().email().required() })),
  });

  const { isLoading, mutate: sendReceipt, isError, isSuccess } = useSetOrderEmailMutation();

  const submitReceiptForm = handleSubmit(({ customerEmail }) => {
    sendReceipt({
      emailUpdateRequest: {
        customerEmail,
      },
      orderUuid,
    });
  });

  return (
    <Stack as="form" px={2} gap={4} onSubmit={submitReceiptForm}>
      <Text fontSize={32} fontWeight={700}>
        <Trans>Want a receipt?</Trans>
      </Text>
      <Flex flexWrap="wrap" gap={2} alignItems="baseline">
        <Controller
          name="customerEmail"
          control={control}
          render={({ field, fieldState: { invalid } }) => (
            <FormControl minWidth={300} maxWidth="fit-content" isRequired isInvalid={invalid}>
              <InputGroup>
                <Input
                  {...field}
                  backgroundColor="white"
                  maxWidth={450}
                  placeholder={t`Type your email`}
                  variant="outline"
                  width="100%"
                  size="lg"
                />
              </InputGroup>
              <FormHelperText marginLeft={4}>
                <Trans>Paperless for the planet 💚</Trans>
              </FormHelperText>
            </FormControl>
          )}
        />
        <Button isLoading={isLoading} loadingText={<Trans>Submit</Trans>} type="submit" size="lg">
          <Trans>Submit</Trans>
        </Button>
      </Flex>
      <Collapse animateOpacity in={isSuccess}>
        <Alert
          background="green.success.25"
          border="1px solid"
          borderColor="green.success.300"
          borderRadius={12}
          gap={3}
          maxW="350px"
          padding={2}
          status="success"
          width="fit-content"
        >
          <CheckCircleIcon color="green.success.300" />
          <Text fontSize="sm" fontWeight={600} color="green.success.700">
            <Trans>Receipt sent</Trans>
          </Text>
        </Alert>
      </Collapse>
      <Collapse animateOpacity in={isError}>
        <Alert
          background="red.error.25"
          border="1px solid"
          borderColor="red.error.300"
          borderRadius={12}
          gap={3}
          maxW="350px"
          padding={2}
          status="error"
          width="fit-content"
        >
          <WarningIcon color="red.error.600" />
          <div>
            <AlertTitle color="red.error.700" fontSize="md">
              <Trans>Unable to send receipt.</Trans>
            </AlertTitle>
            <AlertDescription fontSize="sm" fontWeight={600} color="red.error.700">
              <Trans>To obtain a receipt, please contact support@taster.com</Trans>
            </AlertDescription>
          </div>
        </Alert>
      </Collapse>
    </Stack>
  );
};
