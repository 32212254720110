import { Stack, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import taskDoneAnim from '@/assets/anim/lottie_done.json';
import { LottieAnimation } from '@/components/LottieAnimation';

export interface PairingTerminalSuccessContentProps {
  locationUuid: string;
}

export const PairingTerminalSuccessContent = ({ locationUuid }: PairingTerminalSuccessContentProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate(`/onSite/${locationUuid}`);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [locationUuid, navigate]);

  return (
    <Stack paddingTop={24} gap={24} justifyContent="center" alignItems="center">
      <LottieAnimation animation={taskDoneAnim} animationOptions={{ loop: false }} />
      <Text fontWeight={500} fontSize={32}>
        <Trans>Card machine paired</Trans>
      </Text>
      <Text fontWeight={300} fontSize={24}>
        <Trans>You will be redirected to the Click & Collect shortly</Trans>
      </Text>
    </Stack>
  );
};
