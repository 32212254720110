import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Grid, Heading, IconButton, SystemStyleObject } from '@chakra-ui/react';
import { t } from '@lingui/macro';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { SettingsAccessButton } from '@/components/SettingsAccessButton';
import { WebviewMessage } from '@/components/WebviewMessage';

type HeaderProps = {
  className?: string;
  hasArrowBack?: boolean;
  hasSettingsButton?: boolean;
  showWebviewWarning?: boolean;
  sx?: SystemStyleObject;
  title: ReactNode;
  url?: string;
};

export const Header = ({
  className,
  hasArrowBack = false,
  hasSettingsButton = false,
  showWebviewWarning = false,
  sx,
  title,
  url,
}: HeaderProps) => {
  return (
    <Box sx={sx}>
      <Grid
        alignItems="center"
        as="header"
        backgroundColor="white"
        boxShadow="0px 4px 8px rgba(0, 0, 0, 0.03)"
        className={className}
        gridTemplateColumns="1fr auto 1fr"
        p={4}
        shadow="base"
      >
        {hasArrowBack && (
          <IconButton
            aria-label={t`Go back`}
            as={Link}
            colorScheme="gray"
            fontSize={24}
            icon={<ArrowBackIcon />}
            sx={{ justifySelf: 'start' }}
            to={url}
            variant="ghost"
          />
        )}
        <Heading
          as="h4"
          gridColumnStart={2}
          justifySelf="center"
          marginX="auto"
          size="md"
          textAlign="center"
          sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: '2',
          }}
        >
          {title}
        </Heading>
        {hasSettingsButton && <SettingsAccessButton sx={{ justifySelf: 'end' }} />}
      </Grid>
      {showWebviewWarning && <WebviewMessage />}
    </Box>
  );
};
