import { CloseIcon } from '@chakra-ui/icons';
import { Button, SystemStyleObject } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useNavigate } from 'react-router-dom';

import { ReadiedOrderSummary } from '@/api/gateway-click-collect/orders';
import { useCancelActionMutation } from '@/api/gateway-click-collect/terminals';
import { useCart } from '@/contexts/cart';
import { SessionEndReasonEnum, useOnSiteSession } from '@/contexts/onSiteSession';

export interface CancelPaymentButtonProps {
  locationUuid: string;
  order?: ReadiedOrderSummary;
  sx?: SystemStyleObject;
  terminalUuid: string;
}

export const CancelOrderButton = ({ locationUuid, order, sx, terminalUuid }: CancelPaymentButtonProps) => {
  const navigate = useNavigate();
  const { endOnSiteSession } = useOnSiteSession();
  const { isLoading, mutate } = useCancelActionMutation();
  const { resetCart } = useCart();

  const cancelOrder = () => {
    endOnSiteSession({
      sessionEndReason: SessionEndReasonEnum.ABANDON,
    });

    if (order) {
      mutate(
        {
          locationUuid,
          terminalUuid,
        },
        {
          onSettled: () => {
            resetCart();
            navigate(`/onSite/${locationUuid}`, { state: { startSession: true } });
          },
        }
      );
    } else {
      resetCart();
      navigate(`/onSite/${locationUuid}`, { state: { startSession: true } });
    }
  };

  return (
    <Button
      colorScheme="gray"
      isLoading={isLoading}
      leftIcon={<CloseIcon fontSize={12} mr={2} />}
      loadingText={<Trans>Cancelling order</Trans>}
      onClick={cancelOrder}
      size="lg"
      sx={sx}
    >
      <Trans>Cancel order</Trans>
    </Button>
  );
};
