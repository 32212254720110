import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import ApiError from '@/api/ApiError';
import { useTasterApis } from '@/api/TasterApiContext';

import { InitializationBundle, PairTerminalRequest } from './types';

export const usePairTerminalMutation = (
  options?: Omit<UseMutationOptions<InitializationBundle, ApiError, PairTerminalRequest>, 'mutationFn'>
) => {
  const {
    gatewayClickCollect: { terminalsApi },
  } = useTasterApis();

  return useMutation({
    ...options,
    mutationFn: (requestParams) => {
      return terminalsApi.pairTerminal(requestParams);
    },
  });
};
